<!--
 * @Author: DZM
 * @Date: 2022-03-23 18:16:39
 * @LastEditors: lijing
 * @LastEditTime: 2022-09-05 17:20:34
 * @Description:
-->
<template>
  <div class="content infoPark">
    <!-- 搜索 -->
    <div class="parkSearchInfo">
      <el-autocomplete
        popper-class="my-autocomplete"
        v-model="parkName"
        style="margin-top: 6px; margin-left: 10px"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入停车场"
        :trigger-on-focus="false"
        @select="handleSelect"
      >
        <template slot-scope="{ item }">
          <div class="name">{{ item.parkName }}</div>
        </template>
      </el-autocomplete>
    </div>
    <!-- 车场详细信息 -->
    <div class="infos">
      <div class="infos_parkName">{{ parkDetailValue.parkName || "..." }}</div>
      <div class="infos_address">
        <img src="../img/lng.png" alt="" />
        <el-tooltip
          class="item"
          effect="dark"
          :content="parkDetailValue.parkAddress"
          placement="right-end"
        >
          <span>{{ parkDetailValue.parkAddress | wordProcessing(20) }}</span>
        </el-tooltip>
      </div>
      <div class="infos_merchants">
        所属商户：<span class="infos_merchantsFont">{{ parkDetailValue.operationName }}</span>
      </div>
      <div class="infos_rule">
        计费规则：
        <el-tooltip
          class="item"
          effect="dark"
          :content="parkDetailValue.thirdFeeDesc"
          placement="right-end"
        >
          <span class="infos_merchantsFont">{{
            parkDetailValue.thirdFeeDesc | wordProcessing(18)
          }}</span>
        </el-tooltip>
      </div>
      <div
        class="infos_image"
        :style="{ 'background-image': `url(${parkDetailValue.parkImg})` }"
      ></div>
      <div class="infos_onthejob">
        在岗人数：<span class="infos_merchantsFont">{{ parkDetailValue.parkPdaManagerName }}</span>
      </div>
      <div class="infos_equipment">
        设备类型：<span class="infos_merchantsFont">{{
          parkDetailValue.parkEquipmentTypeName
        }}</span>
      </div>
      <div class="infos_berth">
        泊位占用：
        <span class="infos_merchantsFont">{{ parkDetailValue.occupyAmount }}</span>
        <span class="infos_merchantsFont">/</span>
        <span class="infos_merchantsFont">{{ parkDetailValue.amount }}</span>
      </div>
    </div>
    <div class="control">
      <el-popover
        placement="top-start"
        :hide-after="3"
        popper-class="monitoring_video_overview"
        title=""
        width="200"
        trigger="manual"
        content="未配置视频监控"
        v-model="visible"
      >
        <div @click="controlClick(1)" slot="reference" class="mouse">
          <span :class="numColor == 1 ? 'colorSpan' : ''">监控视频</span>
          <img v-if="numColor == 1" src="../img/arrow.png" alt="" />
        </div>
      </el-popover>
      <div @click="controlClick(2)" class="mouse">
        <span :class="numColor == 2 ? 'colorSpan' : 'colorDefault'">泊位实况</span>
        <img v-if="numColor == 2" src="../img/arrow.png" alt="" />
      </div>
    </div>
    <berthDialog :parkDetailValue="parkDetailValue" ref="berthDialog"></berthDialog>
    <monitor-details
      ref="monitorDetails"
      :monitorList="videoList"
      :monitorParkName="parkDetailValue.parkName"
    />
  </div>
</template>

<script>
// import berthDialog from './berth.vue'
import berthDialog from "@/views/javaParkinganalysis/homeOverview/chartsOweStyle/berth";
// 车场监控列表
import MonitorDetails from "@/views/summarize/components/newMonitorDetails";
export default {
  name: "info",
  data() {
    return {
      visible: false,
      parkName: "",
      parkDetailValue: [],
      numColor: 1,
      videoList: [],
    };
  },
  components: { berthDialog, MonitorDetails },
  computed: {
    getnewRegionalCollection() {
      return this.$store.state.newregionalCollection;
    },
  },
  watch: {
    getnewRegionalCollection: {
      handler(newVal) {
        this.getDataVideoList(newVal.parkId);
        this.getParkInfo(newVal.parkId, "#parkId" + newVal.parkId, newVal.onclickTag);
      },
    },
  },
  methods: {
    // 视频监控列表
    getDataVideoList(parkId) {
      this.$axios
        .get("/acb/2.0/monitorEquipment/listMonitorEquipment", {
          data: {
            page: 1,
            pageSize: 15,
            parkId: parkId,
            monitorEquipmentName: "",
          },
        })
        .then((res) => {
          if (res.value.list.length > 0) {
            this.videoList = res.value.list;
          } else {
            this.videoList = [];
          }
        });
    },
    querySearchAsync(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 1,
            pageSize: 15,
            slaveRelations: "0,1",
            parkName: queryString,
          },
        })
        .then((res) => {
          let arr = res.value.list;
          let newArr = [];
          for (let i = 0; i < arr.length; i++) {
            newArr.push(arr[i]);
          }
          cb(newArr);
        });
    },
    handleSelect(item) {
      this.parkName = item.parkName;
      this.$store.commit("SET_newregionalCollection", { parkId: item.parkId, onclickTag: 0 });
      // this.getParkInfo(item.parkId, "#parkId" + item.parkId);
    },
    getParkInfo(parkId, target, onclickTag) {
      // console.log("查询车场详情获取1：" + parkId);
      // console.log("查询车场详情获取2：" + target);
      let that = this;
      this.$axios
        .get("/acb/2.0/map/getParkInfo", {
          data: {
            parkId: parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let item = res.value;
            item.onclickTag = onclickTag;
            // this.map.setCenter([
            //   item.longitude / 1000000,
            //   item.latitude / 1000000,
            // ]);
            this.parkName = item.parkName;
            this.parkDetailValue = res.value;

            this.$store.commit("SET_Coordinate", { coordinate: item });

            // let lnglat = [item.longitude / 1000000, item.latitude / 1000000];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 监控/泊位
    controlClick(num) {
      this.numColor = num;
      if (num == 2) {
        this.$refs.berthDialog.getParkList(this.parkDetailValue.parkId);
      } else {
        if (this.videoList.length > 0) {
          this.$refs.monitorDetails.dialogVisible = true;
        } else {
          // 防抖操作
          if (!this.visible) {
            this.visible = true;
            setTimeout(() => {
              this.visible = false;
            }, 3000);
          }
        }
      }
      // getParkList
    },
  },
  created() {},
  mounted() {},
};
</script>
<style></style>
<style>
/* 背景色 */
.monitoring_video_overview {
  width: 15px !important;
  height: 10px;
  line-height: 10px;
  text-align: center;
  color: #fff;
  border: 0;
  background: rgba(191, 212, 255, 0.19) !important;
  border-radius: 4px !important;
  backdrop-filter: blur(10px) !important;
}

/* 下方是去除三角箭头的代码 */
.monitoring_video_overview[x-placement^="top"] .popper__arrow::after {
  border-top-color: rgba(191, 212, 255, 0.19) !important;
}
.monitoring_video_overview[x-placement^="top"] .popper__arrow {
  border-top-color: rgba(191, 212, 255, 0.19) !important;
}
</style>
<style lang="stylus" scoped>
.parkSearchInfo {
  margin: 0 auto;
  margin-top: 11px;
  z-index: 2;
  width: 405px;
  height: 32px;
  background: linear-gradient(173deg, rgba(170,205,255,0.1000) 0%, rgba(22,76,142,0.2000) 100%);
  box-shadow: inset 2px 2px 2px 0px rgba(149,223,255,0.3);
  border-radius: 4px;
  border: 0px solid;
  background: url('../../../images/selectov.png') no-repeat 375px center;
  border-image: linear-gradient(172deg, rgba(239, 250, 255, 0.5), rgba(55, 211, 255, 0.7)) 0 0;
  backdrop-filter: blur(4px);
  box-sizing: border-box;
  >>> input {
    display: inline-block;
    background: none;
    border: none;
    padding: 0;
    width: 300px;
    height: 38px;
    color: #D8D8D8;
    opacity: 1;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 18px;
    &:hover, &:focus {
      background: transparent;
    }
  }

  .parkSearch-menuBar {
    width: 110px;
    font-family: MicrosoftYaHei;
    color: #F0F0F0;
  }

  .menuBarFs {
    font-size: 14px;
  }
}
.infos {
  width: 100%;
  padding: 0 16px;
  margin-top: 28px;
  color: #D8D8D8;
  .infos_parkName {
    font-size: 16px;
    color: #D6F3FF;
  }
  .infos_address {
    margin-top: 12px;
    font-size: 10px;
    color: #9DA5BE;
    span {
      font-size: 10px;
    }
    img {
      width: 8px;
      height: 11px;
      padding-right: 9px;
    }
  }
  .infos_merchants {
    font-size: 11px;
    margin-top: 12px;
    color: #9DA5BE;
  }
  .infos_rule {
    font-size: 11px;
    margin-top: 12px;
    color: #9DA5BE;
  }
  .infos_image {
    height: 280px;
    margin-top: 20px;
    background-repeat: no-repeat;
    background-size: 380px 100%;
  }
  /* 最大是1300px <=1300px */
  @media screen and (max-width 1300px) {
    .infos_image {
      height: 120px;
      margin-top: 20px;
      background-repeat: no-repeat;
      background-size: 250px 100%;
    }
  }
  .infos_onthejob {
    font-size: 11px;
    color: #9DA5BE;
    margin-top: 15px;
  }
  .infos_equipment {
    font-size: 11px;
    color: #9DA5BE;
    margin-top: 10px;
  }
  .infos_berth {
    font-size: 11px;
    color: #9DA5BE;
    margin-top: 10px;
  }
}
.infos_merchantsFont{
    color: #94B4CA;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
}
.control {
  display: flex;
  justify-content:space-between;
  padding: 0 60px 0px 20px;
  margin-top: 28px;
  margin-bottom: 40px;
  div {
    cursor pointer;
    width: 151px;
    height: 54px;
    line-height: 54px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    position relative;
    span {
      margin-left: 30px;
      height: 38px;
    }
    img {
      width: 11px;
      height: 12px;
      position: absolute;
      bottom: -12px;
      left: 55%;
    }
  }
  .colorSpan {
    display: inline-block;
    border-bottom: 1px solid #6EBDD0;
  }
  .colorDefault {
    color: #C8D8E5;
  }
  div:first-child {
    background-image: url('../img/monitoring.png')
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  div:last-child {
    background-image: url('../img/berth_url.png')
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>
