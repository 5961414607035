<!--
 * @Author: DZM
 * @Date: 2022-03-23 18:16:39
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-25 14:15:37
 * @Description: 
-->
<template>
<div class="content">
   <timeType :title="'剩余泊位占比'"></timeType>
    <div class="leftberthForecast" id="berthForecast"></div>
</div>
</template>

<script>
import timeType from '../../componets/title.vue';
import { dateFormat } from "@/common/js/public";
export default {
  name: "berthForecast",
  data() {
    return {
      xList: [],
      dataList: [],
      echartsDOM: null
    };
  },
  computed: {
    getnewRegionalCollection() {
      return this.$store.state.newregionalCollection;
    }
  },
  watch: {
    getnewRegionalCollection: {
      handler(newVal) {
        this.getData(newVal);
      }
    }
  },
  components: {timeType},
  methods: {
    // 获取数据
    getData(data) {
      // this.dataList = [0, 0, 0, 0, 0, 0];
      // this.xList = [0, 0, 0, 0, 0, 0];
      this.dataList = [];
      this.xList = [];
      let nowDate = new Date();
      this.$api.leftSideApi.javaBerthPredict({
        parkId: data.parkId
      }).then(res => {
        if (res.state == 0) {
            res.value.forEach((val) => {
              nowDate.setHours(val.hour);
              nowDate.setMinutes(0);
              this.xList.push(dateFormat(nowDate, "HH:mm"));
              this.dataList.push(Number(val.occupyRatio).toFixed(2));
            })
        };
        this.initCharts();
      })
    },
    // 初始化图表
    initCharts() {
      console.log("top");
      var that = this;
      var chartDom = document.getElementById("berthForecast");
      var myChart = this.$echarts.init(chartDom);
      this.echartsDOM = myChart;
      var option = {
        color: ["rgba(165, 184, 255, 1)", "rgba(255, 255, 255, 1)"],
        tooltip: {
          trigger: "axis",
          formatter: "时间 : {b} <br/> 占比 : {c}%"
        },
        grid: [
          {
            left: '10%',
            right: '5%',
            bottom: '15%',
            top: 50,
            // height: "55%",
          },
        ],
        xAxis: {
          type: "category",
          data: this.xList,
          axisPointer: {
            type: "shadow"
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              type: "solid",
              color: "#3C8BC5", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          axisLabel: {
            color: "#94B4CA", // 刻度样式
          },
          boundaryGap: true,
        },

        yAxis: {
          type: "value",
          name: "剩余泊位占比",
          offset: 0,
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
            padding: [0, -14, 0, 0],
          },
          min: 0,
          max: 100,
          splitNumber: 5,
          axisTick: {
            show: false, // y轴坐标点是否消失
          },
          axisLine: {
            // y轴
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color: "rgba(255, 255, 255, 0.3000)", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#94B4CA",
            },
            formatter: `{value}%`,
          },
        },
        series: [
          {
            type: "line",
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(164, 196, 255, 0.7)", // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: "rgba(131, 168, 255, 0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#A4B8E3",
                  width: 1,
                },
              },
            },
            barWidth: 20,
            data: this.dataList
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initCharts();
    });

    window.addEventListener("resize", () => {
      this.echartsDOM.resize();
    });
  },
};
</script>
<style>
</style>
<style lang="stylus" scoped>
.content {
  padding: 0 16px;
  position relative
  height: 100%;
  .leftberthForecast {
    padding-top: 16px;
    position absolute
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    >>> .tooltip {
      width: 90px;
      background: rgba(191,212,255,0.19);
      border-radius: 4px;
      backdrop-filter: blur(10px);
      color: rgba(214, 243, 255, 1)
      line-height: 25px;
      padding-top: 5px;
      P {
        padding-left: 15px;
      }
    }
  }
}
</style>
