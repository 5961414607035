<!--
 * @Author: DZM
 * @Date: 2022-08-16 13:58:04
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-19 18:18:23
 * @Description: 
-->
<template>
  <div class="content">
      <timeType :title="'利用率'"></timeType>
      <div class="utilization" id="utilization"></div>
  </div>
</template>
<script>
import timeType from '../../componets/title.vue'
import * as echarts from 'echarts'
export default {
  props: ['dataList'],
  data() {
    return {
      echartsDOM: null
    }
  },
  watch: {
    dataList: {
      handler(newVal) {
        this.initCharts(newVal)
      }
    }
  },
  components: {timeType},
  mounted() {
    this.$nextTick(() => {
       this.initCharts();
    });
  },
  methods: {
    // 数据处理
    setFilter(num) {
      if (num) return (num * 100).toFixed(2)
      return 0
    },
    // 初始化图表
    initCharts(data) {
      var that = this;
      var chartDom = document.getElementById("utilization");
      var myChart = this.$echarts.init(chartDom);
      this.echartsDOM = myChart
      var option = {
          series: [
              // 最小
              {
                  type: 'gauge',
                  radius: '25%', // 位置
                  center: ['50%', '60%'],
                  min: 0,
                  max: 100,
                  startAngle: 180,
                  endAngle: 0,
                  axisLine: {
                      show: true,
                      lineStyle: {
                          // 轴线样式
                          width: 120, // 宽度
                          opacity: 0.4,
                          color: [
                              [1, 
                              new echarts.graphic.RadialGradient(0.5, 1, 1, [
                                      {
                                          offset: 0.8,
                                          color: 'rgba(106, 205, 255, 0.2600)',
                                      },
                                      {
                                          offset: 0.3,
                                          color: 'rgba(106, 205, 255, 0)',
                                      },
                                  ])],
                          ], // 颜色
                      },
                  },
                  axisTick: {
                      // 刻度
                      show: false,
                  },
                  splitLine: {
                      // 分割线
                      show: false,
                  },
                  axisLabel: {
                      // 刻度标签
                      show: false,
                  },
                  pointer: {
                      // 仪表盘指针
                      show: false,
                  },
                  detail: {
                      // 仪表盘详情
                      show: false,
                  },
            },
            // 双刻度 数字
            {
              type: 'gauge',
              center: ['50%', '60%'],
              radius: "60%",
              startAngle: 210,
              endAngle: -30,
              min: 0,
              max: 100,
              splitNumber: 10,
              itemStyle: {
                color: 'rgba(107, 205, 255, 0.2)'
              },
              progress: {
                show: true,
                width: 9
              },
              pointer: {
                show: true,
                width: 3,
                length: '70%',
                itemStyle: {
                  // 4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                  color: new echarts.graphic.LinearGradient(
                      0, 1, 0, 0,
                      [
                          {offset: 1, color: 'rgba(106, 205, 255, 1)'},
                          {offset: 0, color: 'rgba(106, 205, 255, 0)'}
                      ]                
                  )
                }
              },
              axisLine: {
                lineStyle: {
                  width: 0,
                  opacity: 0,
                  color: [
                    [data?.averageDailyBerthUtilizationRate ? data.averageDailyBerthUtilizationRate / 100 : 0, 'rgba(107, 205, 255, 1)'], // 进度色
                    
                    [1, 'rgba(216, 216, 216, 1)'], // 背景色
                  ]
                }
              },
              axisTick: {
                distance: -11,
                splitNumber: 5,
                lineStyle: {
                  width: 1,
                  color: 'auto'
                }
              },
              splitLine: {
                distance: -9,
                length: 6,
                lineStyle: {
                  width: 1,
                  color: 'auto'
                }
              },
              axisLabel: {
                distance: -24,
                color: 'rgba(187, 235, 255, 1)',
                fontSize: 12
              },
              anchor: {
                show: false
              },
              title: {
                show: false
              },
              detail: {
                valueAnimation: true,
                width: '60%',
                lineHeight: 20,
                borderRadius: 8,
                fontSize: 15,
                fontWeight: 'bolder',
                formatter: function (value) {
                   return `${Number.isNaN(value) ? 0 : value} %`
                },
                color: 'rgba(167, 229, 255, 1)'
              },
              data: [
                {
                  value: data ? data.averageDailyBerthUtilizationRate : 0
                }
              ]
            }, 
            // 细线背景
            {
              type: 'gauge',
              center: ['50%', '60%'],
              radius: "60%",
              startAngle: 210,
              endAngle: -30,
              min: 0,
              max: 100,
              progress: {
                show: false,
              },
              pointer: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  width: 10,
                  color: [
                    [0.1, '#2E3756'], // 0~10% 红轴
                    
                    [1, '#2E3756'], // 20~30% 蓝轴
                  ]
                }
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              anchor: {
                show: false
              },
              title: {
                show: false
              },
              detail: {
                show: false
              },
              data: [
                {
                  value: data ? data.averageDailyBerthUtilizationRate : 0
                }
              ]
            },
            // 细线
            {
              type: 'gauge',
              center: ['50%', '60%'],
              radius: "60%",
              startAngle: 210,
              endAngle: -30,
              min: 0,
              max: 100,
              itemStyle: {
                color: '#6FCDFF'
              },
              progress: {
                show: true,
                width: 3
              },
              pointer: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: false
              },
              axisLabel: {
                show: false
              },
              detail: {
                show: false
              },
              data: [
                {
                  value: data ? data.averageDailyBerthUtilizationRate : 0
                }
              ]
            }
          ]
      };

      myChart.setOption(option);
      
      window.addEventListener('resize', () => {
        myChart.resize();
        setTimeout(() => {
          this.initCharts();
        }, 2000)
      })
    },
  }
}
</script>
<style lang="stylus" scoped>
.content {
  height: 100%;
  padding-top: 24px;
  padding-left: 16px;
  .utilization {
    height: 19vh;
  }
}
</style>
