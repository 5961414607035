<template>
  <!-- 停车收入统计 -->
  <div class="chartsContent-wrap">
    <timeType @getTimeType="getTimeType" :deleteToday="true" :tip="'车场在不同时段累计收入的变化趋势'" :title="'停车收入统计'"></timeType>
    <div class="leftThreeChart" id="incomeStatistics1"></div>
  </div>
</template>

<script>
import timeType from '../componets/timeType.vue'
export default {
  name: "overviewParkBusiness",
  data() {
    return {
      activeLI: 1,
      xList: [],
      dataList: [],
      startDate: '',
      endDate: '',
      type: 1,
    };
  },
  components: {
    timeType
  },
  computed: {
    getRegionalCollection() {
      return this.$store.state.regionalCollection;
    }
  },
  watch: {
    getRegionalCollection: {
      handler(newVal) {
        console.log(newVal, '----------------------')
        this.info = newVal;
        this.getData(newVal);
      }
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initCharts();
    });

    window.addEventListener("resize", () => {
      this.echartsDOM.resize();
    });
  },
  methods: {
    setNum(num) {
      if (num > 0 && num < 4) {
        return 1
      } else if (num == 4) {
        return 2
      } else {
        return 3
      }
    },
    getData(data) {
      this.xList = [];
      this.dataList = [];
      this.$api.rightSideApi.javaParkIncomeTrend({
        reportType: this.setNum(this.type),
        areaId: data.areaId || '',
        dataSource: data.dataSource,
        startDate: this.startDate || this.$TimeAll(3, false)[0],
        endDate: this.endDate || this.$TimeAll(3, false)[1]
      }).then(res => {
        let arr = res.value;
        if (this.type == 0) {
            Object.keys(arr[0]).forEach((val) => {
              this.xList.push(val);
              this.dataList.push(arr[0][val])
            })
        } else {
          if (arr.length > 0) {
            arr.forEach((val) => {
              this.xList.push(val.reportDate);
              this.dataList.push(val.money / 100);
            })
          }
        }
        console.log(this.dataList, "this.dataList")
        this.initCharts();
      })
    },
    // 获取时间
    getTimeType(date) {
      console.log(111, date);
      this.startDate = date[0];
      this.endDate = date[1];
      this.type = date[2];
      this.getData(this.info)
    },
    initCharts() {
      console.log("top");
      var that = this;
      var chartDom = document.getElementById("incomeStatistics1");
      var myChart = this.$echarts.init(chartDom);
      this.echartsDOM = myChart;
      var option = {
        color: ["rgba(165, 184, 255, 1)", "rgba(255, 255, 255, 1)"],
        tooltip: {
          trigger: "axis",
        },

        grid: [
          {
            left: '10%',
            right: 0,
            bottom: '20%',
            top: 30,
            // height: "55%",
          },
        ],
        xAxis: {
          type: "category",
          data: this.xList,

          axisPointer: {
            type: "shadow",
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              type: "solid",
              color: "#3C8BC5", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          axisLabel: {
            color: "#94B4CA", // 刻度样式
          },
          boundaryGap: true,
        },

        yAxis: {
          type: "value",
          name: "停车收入(元)",
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
            padding: [0, -14, 0, 0],
          },
          // min: 0,
          // max: 25,
          // interval: 5,
          splitNumber: 3,
          
          axisTick: {
            show: false, // y轴坐标点是否消失
          },
          axisLine: {
            // y轴
            show: true,
            onZero: true,
            lineStyle: {
              type: "solid",
              color: "#3C8BC5", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dotted",
              color: "#3C8BC5", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#94B4CA",
            },
            formatter: `{value}`,
          },
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            height: 4,
            left: "1%",
            right: "1%",
            bottom: "0",
            start: 0,
            end: 100,
            filterMode: 'empty',
            fillerColor: "rgba(78, 108, 167, 1)",
            backgroundColor: "rgba(41, 62, 103, 1)",
            borderColor: "none",
            textStyle: {
              color: "#d4ffff",
              fontSize: 11,
              border: "none",
            },
          },
          {
            type: "inside",
          },
        ],
        series: [
          {
            name: "停车收入",
            type: "line",
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(164, 196, 255, 0.7)", // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: "rgba(131, 168, 255, 0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#A6B8FF",
                  width: 1,
                },
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + "";
              },
            },
            data: this.dataList
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartsContent-wrap {
  margin-top: 16px;
  padding: 0 16px;

  .leftThreeChart {
    padding-top: 16px;
    height: 17vh;
  }
}
</style>
