<!--
 * @Author: DZM
 * @Date: 2022-08-16 17:15:50
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-24 13:55:16
 * @Description: 
-->
<template>
  <div class="content">
    <div class="img">
      <div class="guestUnitPrice">
      </div>
      <div class="line"></div>
      <div class="averageBerthRevenue"></div>
    </div>
    <div class="text">
      <div class="left">
        <p>客单价（元）</p>
        <p style="font-family: Digital-7Mono;">{{ customerUnitPrice | moneyHandling}}</p>
      </div>
      <div class="right">
        <p>平均泊位收入（元）</p>
        <p style="font-family: Digital-7Mono;">{{ avgBerthIncome | moneyHandling}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      customerUnitPrice: 0,
      avgBerthIncome: 0
    }
  },
  computed: {
    getnewRegionalCollection() {
      return this.$store.state.newregionalCollection;
    }
  },
  watch: {
    getnewRegionalCollection: {
      handler(newVal) {
        // console.log(newVal, '----------------------')
        this.getData(newVal);
      }
    }
  },
  methods: {
    getData(data) {
      this.customerUnitPrice = 0;
      this.avgBerthIncome = 0;
      this.$api.rightSideApi.javaBigavgBerthIncome({
        parkId: data.parkId
      }).then(res => {
        if (res.state == 0 && res.value) {
          this.customerUnitPrice = res.value[0]?.customerUnitPrice;
          this.avgBerthIncome = res.value[0]?.singleBerthShouldPayMoney;
        }
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.content {
  padding-top: 40px;
  .img {
    display: flex;
    justify-content: space-evenly;
    align-items:center;
    .guestUnitPrice {
      width: 96px;
      height: 96px;
      background-image: url('../img/guestUnitPrice.png')
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .line {
      width: 1px;
      height: 60px;
      background: #2E516D;
    }
    .averageBerthRevenue {
      width: 96px;
      height: 96px;
      background-image: url('../img/averageBerthRevenue.png')
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  .text {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #DBF3FF;
    line-height: 30px;
    text-shadow: 0px 1px 9px #00B6FF;
    .left {
      width: 50%;
      p:first-child {
        text-indent: 12px;
      }
      p:last-child {
        color: rgba(184, 234, 255, 1);
        font-size: 21px;
      }
    }
    .right {
      width: 50%
      p:first-child {
        text-indent: 12px;
      }
      p:last-child {
        color: rgba(184, 234, 255, 1);
        font-size: 21px;
      }
    }
  }
}
</style>
