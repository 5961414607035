<!--
 * @Author: DZM
 * @Date: 2022-03-23 18:16:39
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-29 17:03:19
 * @Description: 
-->
<template>
<div class="content">
   <timeType :title="'道路收入'" class="spacing"></timeType>
   <ytdTime @getTimeType="getTimeType" ref="tab"></ytdTime>
    <div class="serviceIncome" id="serviceIncome"></div>
</div>
</template>

<script>
import timeType from '../../componets/title.vue'
import ytdTime from '../../componets/ytdTime.vue'
export default {
  name: "serviceIncome",
  data() {
    return {
      xList: [],
      dataList: [],
      echartsDOM: null,
      data: {}
    };
  },
  computed: {
    getnewRegionalCollection() {
      return this.$store.state.newregionalCollection;
    }
  },
  watch: {
    getnewRegionalCollection: {
      handler(newVal) {
        this.data = newVal;
        this.$refs.tab.activeLI = 3;
        this.getData(newVal);
      }
    }
  },
  components: {timeType, ytdTime},
  methods: {
    // 获取数据
    getData(data, date) {
      this.dataList = [];
      this.xList = [];
      this.$api.rightSideApi.javaParkIncome({
        parkId: data.parkId,
        reportType: date ? date[2] : 3
      }).then(res => {
        if (res.state == 0) {
          res.value.forEach((val) => {
            this.dataList.push({
              value: val.money ? (val.money / 100).toFixed(2) : 0
            });
            this.xList.push(val.reportDate)
          })
          this.initCharts();
        }
      })
    },
    // 日期
    getTimeType(date) {
      // console.log(date, '------------')
      this.getData(this.data, date);
    },
    // 图标初始化
    initCharts() {
      console.log("top");
      var that = this;
      var chartDom = document.getElementById("serviceIncome");
      var myChart = this.$echarts.init(chartDom);
      this.echartsDOM = myChart;
      var option = {
        color: ["rgba(165, 184, 255, 1)", "rgba(255, 255, 255, 1)"],
        tooltip: {
          trigger: "axis",
          backgroundColor: 0,
          padding: 0,
          borderWidth: 0,
          formatter(params) {
            let tooltips = 
            `
              <div class="tooltip">
                <p>${params[0].axisValue}</p>
                <p>${params[0].data.value || 0}元</p>
              </div>
            `;
            return tooltips
          },
        },

        grid: [
          {
            left: '10%',
            right: '5%',
            bottom: '15%',
            top: 10,
            // height: "55%",
          },
        ],
        xAxis: {
          type: "category",
          data: this.xList,

          axisPointer: {
            type: "shadow",
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              type: "solid",
              color: "#3C8BC5", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          axisLabel: {
            color: "#94B4CA", // 刻度样式
          },
          boundaryGap: true,
        },

        yAxis: {
          type: "value",
          axisTick: {
            show: false, // y轴坐标点是否消失
          },
          axisLine: {
            // y轴
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color: "rgba(255, 255, 255, 0.3000)", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#94B4CA",
            },
            formatter: `{value}`,
          },
        },
        // dataZoom: [
        //   {
        //     type: "slider",
        //     show: true,
        //     height: 4,
        //     left: "1%",
        //     right: "1%",
        //     bottom: "0",
        //     start: 0,
        //     end: 100,
        //     filterMode: 'empty',
        //     fillerColor: "rgba(78, 108, 167, 1)",
        //     backgroundColor: "rgba(41, 62, 103, 1)",
        //     borderColor: "none",
        //     textStyle: {
        //       color: "#d4ffff",
        //       fontSize: 11,
        //       border: "none",
        //     },
        //   },
        //   {
        //     type: "inside",
        //   },
        // ],
        series: [
          {
            name: "停车收入",
            type: "line",
            smooth: true,
            // areaStyle: {
            //   normal: {
            //     color: {
            //       x: 0,
            //       y: 0,
            //       x2: 0,
            //       y2: 1,
            //       colorStops: [
            //         {
            //           offset: 0,
            //           color: "rgba(164, 196, 255, 0.7)", // 0% 处的颜色
            //         },
            //         {
            //           offset: 0.7,
            //           color: "rgba(131, 168, 255, 0)", // 100% 处的颜色
            //         },
            //       ],
            //       globalCoord: false, // 缺省为 false
            //     },
            //   },
            // },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#6585C7",
                  width: 1,
                },
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + "";
              },
            },
            data: this.dataList
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initCharts();
    });

    window.addEventListener("resize", () => {
      this.echartsDOM.resize();
    });
  },
};
</script>
<style>
</style>
<style lang="stylus" scoped>
.content {
  padding: 0 16px;
  position relative
  height: 100%;
  top: 15px;
  .serviceIncome {
    position absolute
    bottom: 0;
    right: 0;
    top: 52px;
    left: 0;
    >>> .tooltip {
      width: 90px;
      background: rgba(191,212,255,0.19);
      border-radius: 4px;
      backdrop-filter: blur(10px);
      color: rgba(214, 243, 255, 1)
      line-height: 25px;
      padding-top: 5px;
      P {
        padding-left: 15px;
      }
    }
  }
  .spacing {
    margin-bottom: 10px;
  }
}
</style>
