<!--
 * @Author: DZM
 * @Date: 2022-03-28 14:59:08
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-25 14:13:52
 * @Description: 
-->
<template>
  <!-- 客诉类型分析 -->
    <div class="title">
        <div style="float:left;">
          <ul class="selcetTimeRange">
            <li
              v-for="(item, index) in typeList"
              :key="index"
              :class="activeLI == item.type ? 'activeli' : 'normalli'"
              @click="selcetTimeRange(item.type)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
    </div>
</template>

<script>
export default {
  name: "timeType",
  data() {
    return {
      activeLI: 3,
      typeList: [
        { name: '近7日', type: 3 },
        { name: '近30日', type: 2 },
        { name: '近12月', type: 4 }
      ]
    };
  },
  components: {
  },
  created() {},
  mounted() {},
  methods: {
    selcetTimeRange(num, date) {
      this.activeLI = num;
      let timeArr = [this.$TimeAll(num)[0], this.$TimeAll(num)[1], num];
      this.$emit('getTimeType', timeArr)
    }
  },
};
</script>

<style lang="stylus" scoped>
  .title {
    margin: 5px 0 5px -4px;
  }
  .selcetTimeRange {
   
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 16px;
    height: 18px;
    font-weight: normal;
    text-align: right;

    li {
      font-size: 12px;
      cursor: pointer;
      display: inline-block;
      margin-left: 4px;
      height: 100%;
      line-height: 18px;
      padding: 0 8px;
    }

    .normalli {
      color: #DCDCDC;
      border: 1px solid;
      opacity: 0.8;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }

    .activeli {
      color: #FFFFFF;
      background: linear-gradient(180deg, rgba(0, 255, 253, 0.3) 0%, rgba(0, 255, 250, 0) 100%);
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(14, 255, 253, 1), rgba(6, 255, 251, 0)) 1 1;
      border-bottom: none;
    }
  }
</style>
